import React from 'react';
import logo from './logo-white.png';
import './Header.css';
import './Header-responsive.css';


function Header() {
  return (
    <div className="Header">
      <div className="Header-content">
          <img className="Header-logo" src={logo} alt="logo" />
      </div>
    </div>
  );
}

export default Header;
